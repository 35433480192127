import * as _ from 'lodash';
import { HiveClassBase } from './HiveClassBase';

/**
 * Information about an Hive class definition
 */
export class HiveClass extends HiveClassBase {
  name = '';
  properties: { [name: string]: string } = {};

  constructor(klass?: any) {
    super(klass);
    if (klass) {
      this.name = _.get(klass, 'name');
      this.properties = _.assignIn({}, _.get(klass, 'properties', {}));
    }
  }
}
