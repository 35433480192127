import { HiveInstance } from './HiveInstance';

/**
 * Base class to wrap a raw HiveInstances and extract desired properties
 * It also exposes toHiveInstances in order to rebuild the HiveInstance
 * that should be sent back to the Hive when updating an existing instance
 */
export abstract class AbstractHiveModel {
  id: string;
  created: string;
  modified: string;

  constructor(instance: HiveInstance) {
    if (instance) {
      this.id = instance.id;
      this.created = instance.created;
      this.modified = instance.modified;
    }
  }

  abstract toHiveInstance(): HiveInstance;
}
