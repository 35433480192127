import * as _ from 'lodash';
import { HiveClassBase } from './HiveClassBase';

/**
 * Represents a raw JSON instance from Hive storage
 */
export class HiveInstance {
  id = '';
  created = '';
  modified = '';
  klass: HiveClassBase;
  properties: { [name: string]: any } = {};

  constructor(instance?: any) {
    if (instance) {
      this.id = _.get(instance, 'id');
      this.created = _.get(instance, 'created');
      this.modified = _.get(instance, 'modified');
      this.klass = _.get(instance, 'class') || _.get(instance, 'klass');
      this.properties = _.assignIn({}, _.get(instance, 'properties', {}));
    }
  }
}