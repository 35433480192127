import * as _ from 'lodash';
import * as importedMoment from 'moment';
import { HiveTokenTypes } from './HiveTokenTypes';
const moment = importedMoment;

/**
 * Information about an Hive token
 */
export class HiveAuthToken {

  constructor(public token = '', public expiry = 0, public type: HiveTokenTypes = HiveTokenTypes.APP) {
  }

  /**
   * Number of hours to add to now to detect if a token is expired.
   * @example
   *
   * ```
   * it's 13h and the token expires at 15h, we would check if the 13h + hoursToAddToNow is after 15h
   * which would be true using the default 5h
   * ```
   * @param hoursToAddToNow number of hours from now to ensure the token is still valid
   */
  isExpired(hoursToAddToNow = 0){
    const now = moment().add(hoursToAddToNow, 'h');
    return _.isEmpty(this.token) || now.isAfter(moment.unix(this.expiry));
  }
}