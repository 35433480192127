import { HiveAuthToken } from './HiveAuthToken';
import { IHiveSession } from './IHiveSession';
import { IHiveSessionData } from './IHiveSessionData';

/**
 * Default implementation of the IHiveSession data contract
 */
export class HiveSession implements IHiveSession {
  public username: string;
  public orgId: string;
  public appId: string;
  public accessToken: HiveAuthToken;

  constructor(data: IHiveSessionData) {
    this.username = data.username || '';
    this.orgId = data.orgId || '';
    this.appId = data.appId || '';
    this.accessToken = data.accessToken || new HiveAuthToken();
  }
}
