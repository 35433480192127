/**
 * Base class for an Angular app to target an Hive Environment
 */
export class HiveEnvironment {
  production = false;

  angularAppVersion = '';

  orgId: string;

  appId: string;

  // Application Bee credentials
  beeUser: any = {
    username: '',
    password: ''
  };

  storage: any = {
    namespaces: []
  };

  reactions: any = {
    categories: []
  };

  ports = {
    oauth: 7000,
    reactions: 6700,
    shell: 8000,
    users: 6500
  };

  urls = {
    host: 'http://localhost'
  };
}
