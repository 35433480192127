import * as _ from 'lodash';

/**
 * Basic representation of a raw JSON class from Hive storage
 * This is the most basic information available from a class
 * and the metadata about the class of an Hive storage instance
 */
export class HiveClassBase {
  id = '';
  version = '';

  constructor(klass?: any) {
    if (klass) {
      this.id = _.get(klass, 'id');
      this.version = _.get(klass, 'version');
    }
  }
}
